import React from "react";
import { Box, Typography } from "@mui/material";
import { Colors } from "../theme/Color";
import First from "../assets/first.svg";
import Second from "../assets/second.svg";
import Third from "../assets/third.svg";
import Forth from "../assets/fourth.svg";
import ButtonComponents from "./ButtonComponents";
import { useNavigate } from "react-router-dom";

const SaveTime = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        background: Colors.candiDateBackground,
        clipPath: {
          xs: "polygon(0 0, 100% 0, 100% 93%, 0% 100%)",
          sm: "polygon(0 0, 100% 0, 100% 90%, 0% 100%)",
          md: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
          lg: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
          xl: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
        },
        // paddingBottom: {
        //   xs: "26%",
        //   sm: "26%",
        //   md: "6%",
        //   lg: "6%",
        //   xl: "6%",
        // },
        paddingTop: "4%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "auto",
          maxWidth: {
            xs: "400px",
            sm: "570px",
            md: "800px",
            lg: "980px",
            xl: "1240px",
          },
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
          padding: {
            xs: "20px",
            sm: "20px",
            md: "0",
            lg: "0",
            xl: "0",
          },
          paddingBottom: "4%",
        }}
      >
        <Box
          sx={{
            width: {
              md: "100%",
              lg: "45%",
              xl: "45%",
            },
            textAlign: {
              md: "center",
            },
            paddingBottom: {
              md: "20px",
            },
          }}
        >
          <Typography
            sx={{
              padding: "0px",
              color: Colors.headingColor,
              fontWeight: "700",
              fontSize: {
                xs: "22px",
                sm: "22px",
                md: "30px",
                lg: "35px",
                xl: "40px",
              },
              lineHeight: {
                xs: "35px",
                sm: "35px",
                md: "45px",
                lg: "51px",
                xl: "60px",
              },
              textAlign: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "start",
                xl: "start",
              },
            }}
          >
            Schedule App features
          </Typography>
          <Typography
            sx={{
              padding: 0,
              color: Colors.textColor,
              paddingTop: {
                xs: "5px",
                sm: "7px",
                md: "13px",
                lg: "24px",
                xl: "24px",
              },
              ontWeight: 400,
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "13px",
                lg: "15px",
                xl: "16px",
              },
              lineHeight: {
                xs: "20px",
                sm: "20px",
                md: "20px",
                lg: "20px",
                xl: "24px",
              },
              textAlign: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "start",
                xl: "start",
              },
            }}
          >
            Time tracking apps for all devices are essential tools for
            maximizing productivity and managing time effectively. Whether
            you’re on your smartphone, tablet, or desktop, these apps allow you
            to monitor how you spend your hours, helping you identify
            time-wasting activities and streamline your workflow. With features
            like project tracking, detailed reports, and reminders, they adapt
            to your lifestyle, ensuring you stay organized no matter where you
            are.
          </Typography>
          <Box
            sx={{
              paddingTop: {
                md: "20px",
                lg: "50px",
                xl: "50px",
              },
              display: {
                xs: "flex",
                sm: "flex",
              },
              alignItems: {
                xs: "center",
                sm: "center",
              },
              justifyContent: {
                xs: "start",
                sm: "start",
              },
              paddingY: {
                xs: "20px",
                sm: "20px",
              },
            }}
          >
            <ButtonComponents
              text="Get Your Demo"
              color={Colors.white}
              background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
              width="191px"
              onclick={() => navigate("book-demo")}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "50%",
              xl: "50%",
            },
            height: "100%",
            paddingX: {
              md: "30px",
              lg: "0",
              xl: "0",
            },
            display: {
              md: "flex",
            },
            alignItems: {
              md: "center",
            },
            justifyContent: {
              md: "center",
            },
            flexDirection: {
              md: "column",
            },
            paddingBottom: {
              md: "10%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              width: "100%",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Box
              sx={{
                background: Colors.white,
                boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.05)",
                borderRadius: "40px 40px 0px 40px",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "50%",
                  lg: "50%",
                  xl: "50%",
                },
                height: "350px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: "10px",
              }}
            >
              <img src={First} alt="First" draggable={false} />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "30px",
                  textAlign: "center",
                  background: Colors.footerBackgroundColor,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "#EC7211",
                  paddingTop: "10px",
                }}
              >
                Job Scheduling & Workforce Management
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: "center",
                  color: Colors.descriptionTextColor,
                  paddingTop: "15px",
                }}
              >
                Easily manage your workforce with advanced job scheduling tools.
                Assign tasks, set priorities, and track time-all from one
                intuitive platform. Improve team coordination and maximize
                productivity
              </Typography>
            </Box>
            <Box
              sx={{
                background: Colors.white,
                boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.05)",
                borderRadius: "40px 40px 40px 0px",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "50%",
                  lg: "50%",
                  xl: "50%",
                },
                height: "350px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: "10px",
              }}
            >
              <img src={Second} alt="Second" draggable={false} />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "30px",
                  textAlign: "center",
                  background: Colors.footerBackgroundColor,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "#EC7211",
                  paddingTop: "10px",
                }}
              >
                Time Tracking & Employee Clocking
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: "center",
                  color: Colors.descriptionTextColor,
                  paddingTop: "15px",
                }}
              >
                Track employee work hours in real-time with our built-in time
                tracking and clocking features. Gain better insights into
                productivity and manage your team's time efficiently.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              paddingTop: "20px",
              width: "100%",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Box
              sx={{
                background: Colors.white,
                boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.05)",
                borderRadius: "40px 0px 40px 40px",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "50%",
                  lg: "50%",
                  xl: "50%",
                },
                height: "350px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: "10px",
              }}
            >
              <img src={Third} alt="Third" draggable={false} />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "30px",
                  textAlign: "center",
                  background: Colors.footerBackgroundColor,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "#EC7211",
                  paddingTop: "10px",
                }}
              >
                Payroll Automation
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: "center",
                  color: Colors.descriptionTextColor,
                  paddingTop: "15px",
                }}
              >
                Generate accurate payroll automatically, reducing errors and
                ensuring compliance with tax regulations. Simplify payroll
                processing for all types of employees-hourly or salaried-without
                the hassle
              </Typography>
            </Box>
            <Box
              sx={{
                background: Colors.white,
                boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.05)",
                borderRadius: "0px 40px 40px 40px",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "50%",
                  lg: "50%",
                  xl: "50%",
                },
                height: "350px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: "10px",
              }}
            >
              <img src={Forth} alt="Forth" draggable={false} />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "30px",
                  textAlign: "center",
                  background: Colors.footerBackgroundColor,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "#EC7211",
                  paddingTop: "10px",
                }}
              >
                Automatic Tax Calculation
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: "center",
                  color: Colors.descriptionTextColor,
                  paddingTop: "15px",
                }}
              >
                Handle tax calculations automatically, ensuring compliance and
                accuracy for payroll and invoicing. With tax regulations built
                into the system, stay worry-free about tax deadlines.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SaveTime;
