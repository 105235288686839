import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { mainTheme } from "./theme/Theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ThemeProvider theme={mainTheme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
