import React from "react";
import { Box, Card, Divider, Grid2, Menu, Typography } from "@mui/material";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TaskIcon from "@mui/icons-material/Task";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { Colors } from "../theme/Color";
import { useNavigate } from "react-router-dom"; // Import useNavigate

interface MenuComponentProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
}

const MenuComponent: React.FC<MenuComponentProps> = ({
  anchorEl,
  open,
  onClose,
}) => {
  const navigate = useNavigate(); // Initialize useNavigate

  const features = [
    {
      icon: AccessTimeFilledIcon,
      title: "Time Clock",
      description: "Keep track of your stock levels efficiently.",
      route: "/time-clock",
    },
    {
      icon: CalendarMonthIcon,
      title: "Scheduling",
      description: "Analyze performance and key metrics.",
      route: "/scheduling",
    },
    {
      icon: TaskIcon,
      title: "Task Management",
      description: "Plan and manage tasks efficiently.",
      route: "/task-management",
    },
    {
      icon: FactCheckIcon,
      title: "Form & Checklists",
      description: "Customize your system preferences.",
      route: "/form-checklists",
    },
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{ onMouseLeave: onClose }}
      sx={{ marginTop: "30px" }}
    >
      <Card
        sx={{
          height: "50vh",
          width: "50vw",
          padding: 4,
          display: "flex",
          flexDirection: "column",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box>
          <Typography
            variant="h4"
            marginBottom={2}
            sx={{ color: Colors.headingColor }}
          >
            Easily Manage Your Daily Operations
          </Typography>

          <Typography variant="body1" sx={{ color: Colors.slate_grey }}>
            From scheduling, all the way down to payroll.
          </Typography>
        </Box>
        <Divider sx={{ paddingTop: "10px" }} />
        <Typography
          variant="h6"
          sx={{ paddingY: "20px", color: Colors.headingColor }}
        >
          Features
        </Typography>

        <Grid2 container spacing={4}>
          {features.map(({ icon: Icon, title, description, route }, index) => (
            <Grid2
              key={index}
              size={6}
              container
              spacing={2}
              alignItems="center"
              sx={{
                cursor: "pointer",
                transition: "all 0.3s ease",
                "&:hover": {
                  backgroundColor: Colors.candiDateBackground,
                  "& .MuiTypography-h6": { color: Colors.vivid_mulberry },
                  "& .MuiSvgIcon-root": { color: Colors.vivid_mulberry },
                },
              }}
              onClick={() => navigate(route)} // Navigate to the route on click
            >
              <Icon fontSize="large" sx={{ color: Colors.textColor }} />
              <Box>
                <Typography variant="h6" sx={{ color: Colors.slate_black }}>
                  {title}
                </Typography>
                <Typography variant="body2" sx={{ color: Colors.slate_grey }}>
                  {description}
                </Typography>
              </Box>
            </Grid2>
          ))}
        </Grid2>
      </Card>
    </Menu>
  );
};

export default MenuComponent;
