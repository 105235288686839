import { Box, Typography, List, ListItem, ListItemIcon } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import React from "react";
import chatImage from "../../assets/chatImage.avif";
import { Colors } from "../../theme/Color";

function ComplaintsAlert() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        padding: { xs: "20px", sm: "30px", md: "40px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: { xs: "100%", lg: "1240px" },
          width: "100%",
        }}
      >
        {/* Image Section */}
        <Box
          sx={{
            width: { xs: "100%", md: "60%" },
            maxWidth: { xs: "660px", md: "800px" },
          }}
        >
          <img
            src={chatImage}
            alt="wobbling"
            draggable={false}
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
              borderRadius: 8,
            }}
          />
        </Box>
        {/* Text Section */}
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              color: Colors.headingColor,
              fontSize: {
                xs: "24px",
                sm: "32px",
                md: "55px",
                lg: "55px",
                xl: "55px",
              },
              lineHeight: {
                xs: "20px",
                sm: "20px",
                md: "60px",
                lg: "60px",
                xl: "60px",
              },
              marginBottom: "16px",
            }}
          >
            Keep timesheets compliant with inconsistency alerts
          </Typography>

          {/* List with Check Icons */}
          <List>
            {[
              "Set break and overtime rules in accordance with local, state, and federal labor laws",
              "Receive alerts about discrepancies, like exceeded overtime limits and double bookings",
              "Automatically list hours, breaks, overtime, and time off on timesheets, ready for payroll",
              "Reduce your administrative workload by approving timesheets individually or in bulk",
            ].map((text, index) => (
              <ListItem key={index} sx={{ padding: 0, marginBottom: "12px" }}>
                <ListItemIcon
                  sx={{
                    minWidth: "32px",
                    color: Colors.textColor,
                  }}
                >
                  <CheckIcon />
                </ListItemIcon>
                <Typography
                  sx={{
                    color: Colors.textColor,
                    fontSize: { xs: "14px", md: "16px" },
                  }}
                >
                  {text}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
}

export default ComplaintsAlert;
