import { Box, Typography } from "@mui/material";
import React from "react";
import bookDemo from "../../assets/BookDemo.webp";
import certified from "../../assets/certified.svg";
import { Colors } from "../../theme/Color";
import ButtonComponents from "../ButtonComponents";
import { useNavigate } from "react-router-dom";

function BookDemo() {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          padding: { xs: "20px", sm: "30px", md: "40px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            gap: { xs: "30px", md: "40px" },
            maxWidth: { xs: "100%", lg: "1240px" },
            width: "100%",
          }}
        >
          {/* Text Section */}
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                color: Colors.headingColor,
                fontSize: {
                  xs: "24px",
                  sm: "32px",
                  md: "36px",
                  lg: "42px",
                  xl: "50px",
                },
                lineHeight: {
                  xs: "32px",
                  sm: "40px",
                  md: "48px",
                  lg: "55px",
                  xl: "65px",
                },
                marginBottom: "16px",
              }}
            >
              Win back time with our employee time clock app
            </Typography>
            <Typography
              sx={{
                color: Colors.textColor,
                fontSize: { xs: "14px", md: "20px" },
                lineHeight: { xs: "22px", md: "24px" },
                marginBottom: "32px",
              }}
            >
              Accurately track frontline employee work hours and submit
              timesheets for payroll in a click
            </Typography>

            {/* Buttons */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: "10px", sm: "20px" },
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              <ButtonComponents
                text="Start 14-day free trial"
                color={Colors.white}
                background={`linear-gradient(to right, ${Colors.vivid_mulberry}, ${Colors.slate_blue})`}
                width="250px"
                onclick={() => navigate("book-demo")}
              />
              <ButtonComponents
                text="Book demo"
                color={Colors.vivid_mulberry}
                background="transparent"
                borderColor={Colors.vivid_mulberry}
                width="190px"
                onclick={() => navigate("/learn-more")}
              />
            </Box>

            {/* Text Section with tag... */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingY: "20px",
              }}
            >
              <Typography
                sx={{
                  color: Colors.textColor,
                  fontSize: { xs: "14px", md: "16px" },
                  lineHeight: { xs: "22px", md: "24px" },
                  marginBottom: "32px",
                }}
              >
                No credit card needed
              </Typography>
              <Box
                sx={{
                  width: { xs: "100%", md: "40%" },
                  maxWidth: "560px",
                }}
              >
                <img
                  src={certified}
                  alt="wobbling"
                  draggable={false}
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>
            </Box>
          </Box>

          {/* Image Section */}
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              maxWidth: "660px",
            }}
          >
            <img
              src={bookDemo}
              alt="wobbling"
              draggable={false}
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default BookDemo;
