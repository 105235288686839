import React from "react";
import {
  Typography,
  Box,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid2,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Colors } from "../theme/Color";
import contactForm from "../assets/contactFormImg.jpg";
import ButtonComponents from "./ButtonComponents";

// Validation schema using Yup
const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Work email is required"),
  jobTitle: Yup.string().required("Job title is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  companyName: Yup.string().required("Company name is required"),
  numberOfEmployees: Yup.string().required("Number of employees is required"),
  description: Yup.string(),
});

// List of employee options
const employeeOptions = [
  "1-10",
  "11-50",
  "51-200",
  "201-500",
  "501-1000",
  "1001-5000",
  "5000+",
];

function ContactForm() {
  return (
    <Box
      sx={{
        overflow: "hidden",
        background: Colors.white,
        height: "130vh",
      }}
    >
      <Grid2
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        padding="30px"
      >
        {/* Form Side */}
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Typography
            sx={{
              color: Colors.headingColor,
              textAlign: "start",
              fontWeight: "500",
              paddingBottom: "1rem",
              fontSize: {
                md: "40px",
                lg: "40px",
                xl: "40px",
              },
              lineHeight: {
                md: "45px",
                lg: "51px",
                xl: "60px",
              },
            }}
          >
            Drop your message and we'll reply quickly!
          </Typography>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              jobTitle: "",
              phoneNumber: "",
              companyName: "",
              numberOfEmployees: "",
              countryCode: "",
              description: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              console.log("Form Data:", values);
            }}
          >
            {({ errors, touched, handleChange, values, submitForm }) => (
              <Form>
                <Grid2 container spacing={2}>
                  {/* First Row */}
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <TextField
                      label="First Name *"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      fullWidth
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <TextField
                      label="Last Name *"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      fullWidth
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Grid2>

                  {/* Second Row */}
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <TextField
                      label="Work Email *"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      fullWidth
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <TextField
                      label="Job Title *"
                      name="jobTitle"
                      value={values.jobTitle}
                      onChange={handleChange}
                      fullWidth
                      error={touched.jobTitle && Boolean(errors.jobTitle)}
                      helperText={touched.jobTitle && errors.jobTitle}
                    />
                  </Grid2>

                  {/* Third Row */}
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <TextField
                      label="Phone Number *"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      fullWidth
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <TextField
                      label="Company Name *"
                      name="companyName"
                      value={values.companyName}
                      onChange={handleChange}
                      fullWidth
                      error={touched.companyName && Boolean(errors.companyName)}
                      helperText={touched.companyName && errors.companyName}
                    />
                  </Grid2>

                  {/* Fourth Row */}
                  <Grid2 size={{ xs: 12, md: 12 }}>
                    <FormControl fullWidth>
                      <InputLabel>Number of Employees *</InputLabel>
                      <Select
                        name="numberOfEmployees"
                        value={values.numberOfEmployees}
                        onChange={handleChange}
                        error={
                          touched.numberOfEmployees &&
                          Boolean(errors.numberOfEmployees)
                        }
                      >
                        {employeeOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 12 }}>
                    <TextField
                      label="Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      fullWidth
                      multiline
                      rows={4}
                      error={touched.description && Boolean(errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid2>

                  {/* Submit Button */}
                  <Grid2 size={{ xs: 12, md: 12 }}>
                    <ButtonComponents
                      text="Submit"
                      color={Colors.white}
                      background={`linear-gradient(to right, ${Colors.vivid_mulberry}, ${Colors.slate_blue})`}
                      width="20%"
                      onclick={submitForm}
                    />
                  </Grid2>
                </Grid2>
              </Form>
            )}
          </Formik>
        </Grid2>

        {/* Image Side */}
        <Grid2 size={{ xs: 12, md: 6 }}>
          <img
            style={{
              width: "100%",
              height: "80%",
              objectFit: "cover",
            }}
            src={contactForm}
            alt="contact"
            draggable={false}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default ContactForm;
