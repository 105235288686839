import React from "react";
import { Box, Typography } from "@mui/material";
import { Colors } from "../theme/Color";
import Recruitment from "../assets/recruitment.svg";
import ButtonComponents from "./ButtonComponents";
import { useNavigate } from "react-router-dom";

const RecruitmentSoftware = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          background: Colors.candiDateBackground,
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            margin: "auto",
            maxWidth: {
              md: "800px",
              lg: "980px",
              xl: "1240px",
            },
            height: {
              md: "100vh",
              lg: "80vh",
              xl: "80vh",
            },
          }}
        >
          <Box
            sx={{
              width: "50%",
              maxWidth: "560px",
              paddingTop: "9%",
            }}
          >
            <Typography
              sx={{
                padding: "0px",
                color: Colors.headingColor,
                fontWeight: "700",
                fontSize: {
                  md: "30px",
                  lg: "35px",
                  xl: "40px",
                },
                lineHeight: {
                  md: "45px",
                  lg: "51px",
                  xl: "60px",
                },
              }}
            >
              Who uses Schedule App?
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: "24px",
                ontWeight: 400,
                fontSize: {
                  md: "13px",
                  lg: "15px",
                  xl: "16px",
                },
                lineHeight: {
                  md: "20px",
                  lg: "20px",
                  xl: "24px",
                },
              }}
            >
              Thousands of businesses use Schedule App to track and manage
              millions of employees’ time for payroll, invoicing and scheduling.
              Schedule App is the best automated time tracking software for
              businesses of all sizes.
            </Typography>
            <Box sx={{ paddingTop: "50px" }}>
              <ButtonComponents
                text="Get Your Demo"
                color={Colors.white}
                background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
                width="191px"
                onclick={() => navigate("book-demo")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "9.3%",
            left: "53%",
            width: "90vw",
            height: "100%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "45vw",
              top: "60%",
              left: "30%",
              transform: "translate(-50%,-50%)",
              zIndex: "2",
            }}
          >
            <img
              style={{
                width: "80%",
                height: "100%",
              }}
              src={Recruitment}
              alt="RecruitmentSoftware"
              draggable={false}
            />
          </Box>
        </Box>
      </Box>
      {/* Mobile */}
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
            xl: "none",
          },
          background: Colors.candiDateBackground,
          clipPath: {
            xs: "polygon(0 0, 100% 0, 100% 93%, 0% 100%)",
            sm: "polygon(0 0, 100% 0, 100% 90%, 0% 100%)",
          },
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            maxWidth: {
              xs: "400px",
              sm: "700px",
            },
            margin: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              margin: "auto",
              flexDirection: "column",
              maxWidth: {
                xs: "400px",
                sm: "570px",
              },
              paddingBottom: "2%",
            }}
          >
            <Typography
              sx={{
                padding: "0px",
                color: Colors.headingColor,
                fontWeight: "700",
                fontSize: {
                  xs: "22px",
                  sm: "22px",
                },
                lineHeight: {
                  xs: "35px",
                  sm: "35px",
                },
                textAlign: "center",
              }}
            >
              Who uses Schedule App?
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: "10px",
                ontWeight: 400,
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                },
                lineHeight: {
                  xs: "20px",
                  sm: "20px",
                },
                textAlign: "center",
              }}
            >
              Thousands of businesses use Schedule App to track and manage
              millions of employees’ time for payroll, invoicing and scheduling.
              Schedule App is the best automated time tracking software for
              businesses of all sizes.
            </Typography>
            <Box sx={{ paddingTop: "50px" }}>
              <ButtonComponents
                text="Get Your Demo"
                color={Colors.white}
                background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
                width="191px"
                onclick={() => navigate("book-demo")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              height: {
                xs: "43vh",
                sm: "58vh",
              },
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "350px",
                  sm: "550px",
                },
                height: {
                  xs: "350px",
                  sm: "450px",
                },
              }}
            ></Box>
            <Box
              sx={{
                position: "absolute",
                top: "10%",
                left: "16%",
                width: "70%",
                height: "80%",
              }}
            >
              <img
                src={Recruitment}
                style={{ width: "100%", height: "100%" }}
                draggable={false}
                alt="Recruitment"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RecruitmentSoftware;
