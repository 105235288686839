import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Colors } from "../theme/Color";
import facebook from "../assets/facebook.svg";
import linkedin from "../assets/linkedin.svg";
import call from "../assets/call.svg";
import mail from "../assets/mail.svg";
import address from "../assets/address.svg";
import { useLocation, useNavigate } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";

interface menuType {
  name: string;
  url: string;
}

const firstColumn = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Learn More",
    url: "/learn-more",
  },
];

const secondColumn = [
  {
    name: "Contact",
    url: "/contact-us",
  },
  {
    name: "Demo",
    url: "",
  },
];

const Footer = () => {
  const selectpath = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ background: Colors.white }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            margin: "auto",
            paddingBottom: "8%",
            maxWidth: {
              md: "800px",
              lg: "980px",
              xl: "1240px",
            },
            flexDirection: {
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            },
            gap: {
              xs: "40px",
              sm: "40px",
            },
            padding: {
              xs: "20px",
              sm: "20px",
            },
            flexWrap: {
              sm: "wrap",
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "21%",
                lg: "21%",
                xl: "21%",
              },
            }}
          >
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/");
              }}
            ></Box>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
                padding: 0,
                paddingTop: {
                  xs: "20px",
                  sm: "20px",
                  md: "30px",
                  lg: "30px",
                  xl: "30px",
                },
                color: Colors.descriptionTextColor,
              }}
            >
              Better experience.
              <br /> Best candidates
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "start",
                paddingTop: {
                  xs: "20px",
                  sm: "20px",
                  md: "30px",
                  lg: "30px",
                  xl: "30px",
                },
              }}
            >
              <a href="https://www.facebook.com/profile.php?id=100092995418042">
                <img src={facebook} alt="facebook" draggable={false} />
              </a>
              <a href="https://www.linkedin.com/company/tironem-ai/">
                <img src={linkedin} alt="linkedin" draggable={false} />
              </a>
            </Box>
          </Box>
          <Box
            sx={{
              width: {
                md: "21%",
                lg: "21%",
                xl: "21%",
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "30px",
                background: Colors.footerBackgroundColor,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                textFillColor: "#EC7211",
              }}
            >
              Quick Links
            </Typography>
            <Stack
              direction={"row"}
              spacing={"50px"}
              sx={{
                paddingTop: {
                  xs: "20px",
                  sm: "20px",
                  md: "35px",
                  lg: "35px",
                  xl: "35px",
                },
              }}
            >
              <Stack direction={"column"} spacing={"20px"}>
                {firstColumn.map((menu: menuType, index: number) => (
                  <Box
                    key={index}
                    onClick={() => {
                      navigate(menu.url);
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "24px",
                        padding: 0,
                        cursor: "pointer",
                        color:
                          selectpath.pathname === menu.url
                            ? Colors.vivid_mulberry
                            : Colors.grey,
                      }}
                    >
                      {menu.name}
                    </Typography>
                  </Box>
                ))}
              </Stack>
              <Stack direction={"column"} spacing={"20px"}>
                {secondColumn.map((menu: menuType, index: number) => (
                  <Box
                    key={index}
                    onClick={() => {
                      navigate(menu.url);
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "24px",
                        padding: 0,
                        cursor: "pointer",
                        color:
                          selectpath.pathname === menu.url
                            ? Colors.vivid_mulberry
                            : Colors.grey,
                      }}
                    >
                      {menu.name}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Stack>
          </Box>
          <Box
            sx={{
              width: {
                md: "21%",
                lg: "21%",
                xl: "21%",
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "30px",
                background: Colors.footerBackgroundColor,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                textFillColor: "#EC7211",
              }}
            >
              Contact
            </Typography>
            <Box
              sx={{
                width: "100%",
                gap: "15px",
                display: "flex",
                paddingTop: {
                  xs: "20px",
                  sm: "20px",
                  md: "35px",
                  lg: "35px",
                  xl: "35px",
                },
              }}
            >
              <img src={call} alt="call" draggable={false} />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  padding: 0,
                  color: Colors.descriptionTextColor,
                }}
              >
                +75 123 12 34 567
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                gap: "15px",
                display: "flex",
                paddingTop: "25px",
              }}
            >
              <img src={mail} alt="mail" draggable={false} />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  padding: 0,
                  color: Colors.descriptionTextColor,
                }}
              >
                info@loremipsum.ai
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                gap: "15px",
                display: "flex",
                paddingTop: "25px",
              }}
            >
              <img src={address} alt="address" draggable={false} />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  padding: 0,
                  color: Colors.descriptionTextColor,
                }}
              >
                Lorim ipsum is dummy text of the printing and typesetting
                industry.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <PrivacyPolicy />
    </>
  );
};

export default Footer;
