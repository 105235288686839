import React, { useEffect, useState } from 'react'
import { Box, Drawer, Typography } from '@mui/material'
import menuLogo from '../assets/menu.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { Colors } from '../theme/Color'
import ButtonComponents from './ButtonComponents'
import ButtonComponentsPromo from './ButtonComponentsPromo'
import MenuComponent from './MenuComponent'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

interface menuType {
	name: string
	url: string
}

const menu = [
	{
		name: 'Home',
		url: '/',
	},
	{
		name: 'Products',
		url: '/products',
	},
	{
		name: 'About Us',
		url: '/about-us',
	},
	{
		name: 'Pricing',
		url: '/pricing',
	},
	{
		name: 'Contact',
		url: '/contact-us',
	},
]

const Navbar = () => {
	const [navbarChangeColor, setNavbarChangeColor] = useState(false)
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const navigate = useNavigate()
	const location = useLocation()

	const selectpath = useLocation()

	const changeBackground = () => {
		if (window.scrollY >= 20) {
			setNavbarChangeColor(true)
		} else {
			setNavbarChangeColor(false)
		}
	}

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.addEventListener('scroll', changeBackground)
			return () => {
				window.removeEventListener('scroll', changeBackground)
			}
		}
	})

	const toggleDrawer =
		(open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
			) {
				return
			}
			setIsMenuOpen(open)
		}

	const handleProductsHover = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}
	return (
		<>
			<Box
				sx={{
					width: '100%',
					position: 'fixed',
					zIndex: '999',
					backgroundColor: Colors.white,
				}}
				className={navbarChangeColor ? 'navbar-animation' : ''}
			>
				<Box
					sx={{
						display: {
							xs: 'none',
							sm: 'none',
							md: 'flex',
							lg: 'flex',
							xl: 'flex',
						},
						margin: 'auto',
						height: '86px',
						maxWidth: '1240px',
						paddingX: {
							md: '55px',
							lg: '80px',
							xl: '100px',
						},
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Box
						sx={{ cursor: 'pointer' }}
						onClick={() => {
							navigate('/')
						}}
					>
						<Typography variant={'h6'} color='#EC7211'>
							Logo
						</Typography>
					</Box>
					<Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '40px',
							}}
						>
							{menu.map((menu: menuType, index: number) => (
								<Box
									key={index}
									sx={{
										cursor: 'pointer',
										fontWeight: '500',
										fontSize: '16px',
										lineHeight: '24px',
										display: 'flex',
										alignItems: 'center',
										color:
											selectpath.pathname === menu.url
												? Colors.vivid_mulberry
												: Colors.grey,
									}}
									onClick={() => {
										navigate(menu.url)
									}}
									onMouseEnter={
										menu.name === 'Products' ? handleProductsHover : undefined
									}
								>
									{menu.name}
									{menu.name === 'Products' && <KeyboardArrowDownIcon />}
								</Box>
							))}
							{/* Dropdown for Products */}
							<MenuComponent
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={handleCloseMenu}
							/>
						</Box>
					</Box>
					{location?.pathname === '/book-demo' ? (
						<>
							<Box sx={{ visibility: 'hidden' }}>
								<ButtonComponents
									text='Book a Demo'
									color={Colors.white}
									background={Colors.vivid_mulberry}
									padding={'10px 30px'}
									width='170px'
									onclick={() => navigate('/book-demo')}
								/>
							</Box>
						</>
					) : (
						<>
							<Box>
								<ButtonComponentsPromo
									text='Register'
									color='#FFFFFF'
									background='#EC7211'
									width='200px'
									lineHeight='14px'
									onclick={() =>
										window.open(process.env.REACT_APP_APP_URL + '/register')
									}
								/>
								<ButtonComponentsPromo
									text='Sign In'
									color='#07052A'
									background='#FFFFFF'
									borderColor='#FFFFFF'
									width='155px'
									lineHeight='14px'
									onclick={() =>
										window.open(process.env.REACT_APP_APP_URL + '/auth')
									}
								/>
							</Box>
						</>
					)}
				</Box>

				{/* /// Mobile screen /// */}
				<Box
					sx={{
						display: {
							xs: 'flex',
							sm: 'flex',
							md: 'none',
							lg: 'none',
							xl: 'none',
						},
						margin: 'auto',
						height: '86px',
						width: '100%',
						maxWidth: {
							xs: '400px',
							sm: '700px',
						},
						position: 'relative',
					}}
				>
					<Box
						sx={{
							width: '28px',
							heigth: '28px',
							position: 'absolute',
							left: '6%',
							top: '32%',
						}}
						onClick={toggleDrawer(true)}
					>
						<img src={menuLogo} alt='menuLogo' draggable={false} />
					</Box>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Typography>Logo</Typography>
					</Box>
				</Box>
			</Box>
			<Drawer anchor={'left'} open={isMenuOpen} onClose={toggleDrawer(false)}>
				<>
					<Box
						sx={{ width: { xs: 300, sm: 500 } }}
						role='presentation'
						onClick={toggleDrawer(false)}
						onKeyDown={toggleDrawer(false)}
					>
						<Box
							sx={{
								position: 'absolute',
								background: Colors.white,
								width: '100%',
								height: '100vh',

								overflow: 'hidden',
								zIndex: '9999999',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									paddingY: '15px',
									paddingLeft: '20px',
									boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.05)',
								}}
							>
								<Typography>Logo</Typography>
							</Box>
							<Box sx={{ marginTop: '40px' }}>
								{menu.map((menu: menuType, index: number) => (
									<Box
										key={index}
										sx={{
											cursor: 'pointer',
											fontWeight: '500',
											fontSize: '16px',
											lineHeight: '24px',
											color:
												selectpath.pathname === menu.url
													? Colors.vivid_mulberry
													: Colors.grey,
											paddingY: '15px',
											paddingLeft: '30px',
											background:
												selectpath.pathname === menu.url
													? '#FBF3FD'
													: Colors.white,
										}}
										onClick={() => {
											toggleDrawer(true)
											if (
												menu.name === 'Home' ||
												menu.name === 'Product' ||
												menu.name === 'Contact' ||
												menu.name === 'About Us' ||
												menu.name === 'Pricing'
											) {
												navigate(menu.url)
											}
										}}
									>
										{menu.name}
									</Box>
								))}
							</Box>
						</Box>
					</Box>
				</>
			</Drawer>
		</>
	)
}

export default Navbar
