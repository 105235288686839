import React from "react";
import { Box, Typography } from "@mui/material";
import { Colors } from "../theme/Color";
import wobbling from "../assets/wobbling.svg";
import Page from "../assets/homePage.svg";

const SpeedFlexibility = () => {
  return (
    <>
      <Box sx={{ background: Colors.candiDateBackground }}>
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            background: Colors.white,
            clipPath: {
              xs: "polygon(0 0, 100% 0, 100% 93%, 0% 100%)",
              sm: "polygon(0 0, 100% 90%, 0% 100%)",
              md: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
            },
            display: {
              xs: "block",
              sm: "block",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "0px",
              right: "35%",
              width: "90vw",
              height: "100%",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                width: "45vw",
                top: "50%",
                left: "63%",
                transform: "translate(-50%,-50%)",
                zIndex: "2",
              }}
            >
              <img
                style={{
                  width: "80%",
                  height: "100%",
                }}
                src={Page}
                alt="SpeedFlexibility"
                draggable={false}
              />
            </Box>
            <img
              style={{
                width: "76%",
                height: "100%",
                transform: "rotate(-71.79deg)",
              }}
              src={wobbling}
              alt="wobbling"
              draggable={false}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              maxWidth: {
                xs: "400px",
                sm: "700px",
                md: "800px",
                lg: "980px",
                xl: "1240px",
              },
              height: "100vh",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
              }}
            ></Box>
            <Box
              sx={{
                width: "50%",
                maxWidth: "560px",
                xs: "400px",
                sm: "570px",
              }}
            >
              <Typography
                sx={{
                  padding: "0px",
                  color: Colors.headingColor,
                  fontWeight: "700",
                  fontSize: {
                    xs: "22px",
                    sm: "22px",
                    md: "30px",
                    lg: "35px",
                    xl: "40px",
                  },
                  lineHeight: {
                    xs: "35px",
                    sm: "35px",
                    md: "45px",
                    lg: "51px",
                    xl: "60px",
                  },
                }}
              >
                Why Choose Schedule App
              </Typography>
              <Typography
                sx={{
                  padding: 0,
                  color: Colors.textColor,
                  paddingTop: "24px",
                  ontWeight: 400,
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "13px",
                    lg: "15px",
                    xl: "16px",
                  },
                  lineHeight: {
                    xs: "20px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                    xl: "24px",
                  },
                }}
              >
                <strong>Efficiency at Its Best -</strong>No more juggling
                multiple tools. With everything you need in one place, managing
                your workforce becomes hassle-free. Track time, schedule jobs,
                handle payroll, and invoice clients-all in a few clicks
              </Typography>
              <Typography
                sx={{
                  padding: 0,
                  color: Colors.textColor,
                  paddingTop: "15px",
                  ontWeight: 400,
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "13px",
                    lg: "15px",
                    xl: "16px",
                  },
                  lineHeight: {
                    xs: "20px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                    xl: "24px",
                  },
                }}
              >
                <strong>Seamless Integration -</strong>Schedule App integrates
                seamlessly with your existing systems, allowing you to keep
                track of jobs and payments without disruption. From mobile
                notifications to email alerts, you'll always stay in the loop.
              </Typography>
              <Typography
                sx={{
                  padding: 0,
                  color: Colors.textColor,
                  paddingTop: "15px",
                  ontWeight: 400,
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "13px",
                    lg: "15px",
                    xl: "16px",
                  },
                  lineHeight: {
                    xs: "20px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                    xl: "24px",
                  },
                }}
              >
                <strong>Affordable for All -</strong>We believe great tools
                shouldn't break the bank. Our pricing is designed to be
                accessible, whether you're a startup or an established business.
              </Typography>
              <Typography
                sx={{
                  padding: 0,
                  color: Colors.textColor,
                  paddingTop: "15px",
                  ontWeight: 400,
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "13px",
                    lg: "15px",
                    xl: "16px",
                  },
                  lineHeight: {
                    xs: "20px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                    xl: "24px",
                  },
                }}
              >
                <strong>Save Time, Stay Productive -</strong>By automating
                routine tasks and streamlining communication, our platform lets
                you focus on what matters most­ growing your business.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SpeedFlexibility;
