import React from "react";
import WebRoutes from "./routes/WebRoutes";
import { Box } from "@mui/material";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

const App = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          width: "100%",
        }}
        className="MAINBODY"
      >
        <WebRoutes />
      </Box>
      <Footer />
    </>
  );
};

export default App;
