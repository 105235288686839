import React from "react";
import BookDemo from "./timeClock/BookDemo";
import EmployeeExplore from "./timeClock/EmployeeExplore";
import EmployeeWork from "./timeClock/EmployeeWork";
import StayControl from "./timeClock/StayControl";
import TrackCard from "./timeClock/TrackCard";
import ComplaintsAlert from "./timeClock/ComplaintsAlert";

function TimeClock() {
  return (
    <div>
      <BookDemo />
      <EmployeeExplore />
      <EmployeeWork />
      <StayControl />
      <TrackCard />
      <ComplaintsAlert />
    </div>
  );
}

export default TimeClock;
