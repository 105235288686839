import { Box, Card, Typography } from "@mui/material";
import React from "react";
import { Colors } from "../../theme/Color";
import ButtonComponents from "../ButtonComponents";

function TrackCard() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "30vh",
        padding: "0 16px",
        paddingBottom: "20px",
      }}
    >
      <Card
        sx={{
          background: Colors.candiDateBackground,
          width: { xs: "100%", sm: "80%", md: "70%" },
          padding: "30px 150px",
          borderRadius: "16px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Headings Section */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 400,
            marginBottom: "5px",
            color: Colors.headingColor,
          }}
        >
          Easily Track & Manage Employee Hours
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: "24px",
            color: Colors.textColor,
          }}
        >
          Always know exactly who’s on time, late, and absent
        </Typography>

        {/* Button Section */}
        <ButtonComponents
          text="Let's go"
          color={Colors.white}
          background={`linear-gradient(to right, ${Colors.vivid_mulberry}, ${Colors.slate_blue})`}
          width="190px"
        />
      </Card>
    </Box>
  );
}

export default TrackCard;
