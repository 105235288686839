import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { Colors } from "../theme/Color";
import Prepare from "../assets/prepare.svg";
import PreScreen from "../assets/pre-Screen.svg";
import Assess from "../assets/assess.svg";
import Report from "../assets/reports-solid.svg";

interface dataType {
  title: string;
  src: any;
  discription: string;
}

const Data = [
  {
    title: "Get Notified, Stay Updated",
    src: Prepare,
    discription:
      "Never miss a beat. Receive mobile and email notifications about upcoming tasks, payment status, and more",
  },
  {
    title: "Schedule Jobs, Track Time",
    src: PreScreen,
    discription:
      "Assign jobs, clock in and out, and monitor work hours with real-time data at your fingertips",
  },
  {
    title: "Register Your Business",
    src: Assess,
    discription:
      "Sign up in minutes, customize your settings, and start managing your workforce effortlessly",
  },
  {
    title: "Manage Invoicing & Payroll",
    src: Report,
    discription:
      "Generate invoices, track payments, and handle payroll with built-in tools that simplify every process.",
  },
];

const TopTalent = () => {
  return (
    <Box sx={{ background: Colors.candiDateBackground }}>
      <Box
        sx={{
          background: Colors.white,
          clipPath: {
            xs: "polygon(0 0, 100% 0, 100% 93%, 0% 100%)",
            sm: "polygon(0 0, 100% 0, 100% 90%, 0% 100%)",
            md: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
            lg: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
            xl: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
          },
          padding: {
            xs: "20px 45px",
            sm: "20px 45px",
            md: "0",
            lg: "0",
            xl: "0",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            textAlign: "center",
            paddingTop: "2%",
            maxWidth: {
              md: "800px",
              lg: "980px",
              xl: "1240px",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                padding: "0px",
                color: Colors.headingColor,
                fontWeight: "700",
                fontSize: {
                  xs: "22px",
                  sm: "22px",
                  md: "30px",
                  lg: "35px",
                  xl: "40px",
                },
                lineHeight: {
                  xs: "35px",
                  sm: "35px",
                  md: "45px",
                  lg: "51px",
                  xl: "60px",
                },
              }}
            >
              How It Works
            </Typography>
            <Grid
              container
              sx={{
                paddingTop: {
                  xs: "28px",
                  sm: "30px",
                  md: "60px",
                  lg: "60px",
                  xl: "60px",
                },
                paddingBottom: "5%",
              }}
              spacing={2}
            >
              {Data.map((menu: dataType, index: number) => (
                <Grid item key={index} xl={3} lg={3} md={3}>
                  <Box
                    sx={{
                      height: {
                        xs: "250px",
                        sm: "250px",
                        md: "300px",
                        lg: "380px",
                        xl: "350px",
                      },
                      background: "#FFFFFF",
                      boxShadow: "0px 9px 30px rgba(0, 0, 0, 0.05)",
                      borderRadius: "10px",
                      padding: {
                        xs: "18px 10px",
                        sm: "18px 10px",
                        md: "35px 20px",
                        lg: "35px 20px",
                        xl: "35px 20px",
                      },
                    }}
                  >
                    <img src={menu.src} alt={menu.title} draggable={false} />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: {
                          xs: "16px",
                          sm: "18px",
                          md: "20px",
                          lg: "18px",
                          xl: "20px",
                        },
                        lineHeight: {
                          xs: "22px",
                          sm: "25px",
                          md: "30px",
                          lg: "30px",
                          xl: "30px",
                        },
                        color: Colors.textColor,
                        padding: "0",
                        paddingTop: {
                          xs: "7px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      {menu.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: {
                          xs: "13px",
                          sm: "15px",
                          md: "16px",
                          lg: "16px",
                          xl: "16px",
                        },
                        lineHeight: {
                          xs: "22px",
                          sm: "25px",
                          md: "30px",
                          lg: "30px",
                          xl: "30px",
                        },
                        textAlign: "center",
                        padding: "0",
                        paddingTop: "15px",
                        color: Colors.descriptionTextColor,
                      }}
                    >
                      {menu.discription}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TopTalent;
