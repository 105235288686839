import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import wobbling from "../assets/wobbling.svg";
import wobbling1 from "../assets/wobbling1.svg";
import ButtonComponents from "./ButtonComponents";
import { useNavigate } from "react-router-dom";
import "../../src/index.scss";
import { Colors } from "../theme/Color";

const HardWork = () => {
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    const playVideo = async () => {
      if (videoRef.current) {
        try {
          await videoRef.current.play();
        } catch (error) {
          console.error("Error playing video:", error);
        }
      }
    };

    const handleInteraction = () => {
      document.removeEventListener("click", handleInteraction);
      playVideo();
    };

    document.addEventListener("click", handleInteraction);

    return () => {
      document.removeEventListener("click", handleInteraction);
    };
  }, []);

  return (
    <Box sx={{ background: Colors.alternateSectionBackground }}>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
          background: Colors.backgroundColor,
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            maxWidth: {
              md: "800px",
              lg: "980px",
              xl: "1240px",
            },
            height: "115vh",
            gap: "20px",
          }}
        >
          <Box
            sx={
              {
                // width: "100%",
                // maxWidth: "560px",
              }
            }
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "0px",
                color: Colors.headingColor,
                fontWeight: "400",
                fontSize: {
                  md: "32px",
                  lg: "70px",
                  xl: "60px",
                },
                lineHeight: {
                  md: "52px",
                  lg: "55px",
                  xl: "75px",
                },
              }}
            >
              Welcome to Schedule App
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: 0,
                color: Colors.textColor,
                paddingTop: "24px",
                ontWeight: 400,
                fontSize: {
                  md: "13px",
                  lg: "15px",
                  xl: "16px",
                },
                lineHeight: {
                  md: "20px",
                  lg: "20px",
                  xl: "24px",
                },
              }}
            >
              The All-in-One Job Scheduling Solution For Efficent,Affordable
              Bussiness Management
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
                paddingTop: "50px",
              }}
            >
              <ButtonComponents
                text="Get Your Demo"
                color={Colors.white}
                background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
                width="191px"
                onclick={() => navigate("book-demo")}
              />
              <ButtonComponents
                text="How it Works"
                color={Colors.vivid_mulberry}
                background="transparent"
                borderColor={Colors.vivid_mulberry}
                width="190px"
                onclick={() => navigate("/learn-more")}
              />
            </Box>
            <Box
              sx={{
                paddingY: "20px",
                width: "100%",
                maxWidth: "900px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 0,
                  color: Colors.textColor,
                  paddingTop: "24px",
                  ontWeight: 400,
                  fontSize: {
                    md: "13px",
                    lg: "15px",
                    xl: "16px",
                  },
                  lineHeight: {
                    md: "20px",
                    lg: "20px",
                    xl: "24px",
                  },
                }}
              >
                Managing your workforce and payments doesn't have to be
                complicated or costly. Schedule App simplifies it all. We bring
                together job scheduling, invoicing, payroll, clocking, time
                tracking, and notifications in one easy-to-use platform. Whether
                you're a small business or a large organization, our solution
                saves you time, reduces administrative overhead, and ensures
                your operations run smoothly-all at an affordable price.
              </Typography>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "60%", height: "30%" }}
                src={HomePoster}
                alt=""
              />
            </Box> */}
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "0px",
              left: "60%",
              zIndex: "-1",
              width: "50vw",
              height: "100vh",
            }}
          >
            <img
              // style={{
              //   width: "100%",
              //   height: "100%",
              // }}
              src={wobbling}
              alt="wobbling"
              draggable={false}
            />
          </Box>
        </Box>
      </Box>

      {/* Mobile */}
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
            xl: "none",
          },
          background: Colors.backgroundColor,
          clipPath: {
            xs: "polygon(0 0, 100% 0, 100% 93%, 0% 100%)",
            sm: "polygon(0 0, 100% 0, 100% 90%, 0% 100%)",
          },
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
            paddingTop: {
              xs: "30%",
              sm: "15%",
            },
            maxWidth: {
              xs: "400px",
              sm: "700px",
            },
            margin: "auto",
          }}
        >
          <Box
            sx={{
              fontWeight: 700,
              fontSize: {
                xs: "23px",
                sm: "30px",
              },
              lineHeight: {
                xs: "35px",
                sm: "45px",
              },
              textAlign: "center",
              color: Colors.headingColor,
            }}
          >
            Welcome to Schedule App
          </Box>
          <Typography
            sx={{
              padding: 0,
              color: Colors.textColor,
              paddingTop: "24px",
              ontWeight: 400,
              fontSize: {
                md: "13px",
                lg: "15px",
                xl: "16px",
              },
              lineHeight: {
                md: "20px",
                lg: "20px",
                xl: "24px",
              },
            }}
          >
            Managing your workforce and payments doesn't have to be complicated
            or costly. Schedule App simplifies it all. We bring together job
            scheduling, invoicing, payroll, clocking, time tracking, and
            notifications in one easy-to-use platform. Whether you're a small
            business or a large organization, our solution saves you time,
            reduces administrative overhead, and ensures your operations run
            smoothly-all at an affordable price.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "11%",
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "80%",
                  sm: "65%",
                },
              }}
            ></Box>
            <Box
              sx={{
                height: {
                  xs: "22vh",
                  sm: "40vh",
                },
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: -1,
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: "350px",
                    sm: "530px",
                  },
                  height: {
                    xs: "350px",
                    sm: "450px",
                  },
                }}
              >
                <img
                  src={wobbling1}
                  style={{ width: "100%", height: "100%" }}
                  draggable={false}
                  alt="wobbling"
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              paddingTop: "10px",
              width: "100%",
              mt: "15%",
            }}
          >
            <ButtonComponents
              text="Get Your Demo"
              color={Colors.white}
              background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
              width="170px"
              onclick={() => navigate("book-demo")}
            />
            <ButtonComponents
              text="How it Works"
              color={Colors.vivid_mulberry}
              background="transparent"
              borderColor={Colors.vivid_mulberry}
              width="170px"
              onclick={() => navigate("/learn-more")}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HardWork;
