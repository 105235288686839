import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import { Colors } from "../theme/Color";
import Navbar from "../components/Navbar";
import wobb from "../assets/About-us-Hero2.webp";
import Group from "../assets/Group-Pic-About-us.webp";
import TrophyCase from "../assets/Trophy-case.webp";
import WorkIcon from "@mui/icons-material/Work";
import PersonIcon from "@mui/icons-material/Person";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useNavigate } from "react-router-dom";
import ButtonComponents from "../components/ButtonComponents";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

interface ContactUs {
  title: string;
  paymentText: string;
  buttonText: string;
}

const aboutOptions: ContactUs[] = [
  {
    title: "Start 14-day free trail",
    paymentText: "Considering to join?",
    buttonText: "Start Now",
  },
  {
    title: "Get in touch",
    paymentText: "Would you like to know more?",
    buttonText: "Contact us",
  },
  {
    title: "Follow us",
    paymentText: "Want to stay connected?",
    buttonText: "Follow",
  },
];

const AboutUs = () => {
  const navigate = useNavigate(); // Initialize the react-router-dom navigate function

  // Function to handle button click
  const handleButtonClick = (title: string) => {
    if (title === "Get in touch") {
      navigate("/contact-us"); // Navigate to the contact page when the button is clicked
    }
  };
  const cardData = [
    {
      icon: <ManageAccountsIcon sx={{ color: Colors.headingColor }} />,
      heading: "Managers",
      text: "Bringing clarity and simplicity for smarter, faster decisions",
    },
    {
      icon: <PersonIcon sx={{ color: Colors.headingColor }} />,
      heading: "Employees",
      text: "Providing tools to accelerate productivity and foster connectivity",
    },
    {
      icon: <WorkIcon sx={{ color: Colors.headingColor }} />,
      heading: "Businesses",
      text: "Setting the foundation to focus on growth and fuel success",
    },
  ];

  // Define the array of numbers and their associated headings
  const stats = [
    { number: "295M", heading: "Shifts scheduled" },
    { number: "2.2M", heading: "Tasks completed" },
    { number: "80", heading: "Countries served" },
    { number: "1M", heading: "Active users" },
  ];

  return (
    <>
      <Navbar />
      <Box
        sx={{
          backgroundColor: Colors.backgroundColor,
          padding: "70px 20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: Colors.headingColor,
            fontWeight: "500",
            fontSize: {
              xs: "28px",
              sm: "32px",
              md: "60px",
              lg: "60px",
            },
            lineHeight: {
              xs: "40px",
              sm: "45px",
              md: "50px",
              lg: "60px",
            },
            textAlign: "center",
            paddingTop: "70px",
          }}
        >
          Hard work is hard enough
        </Typography>

        {/* Paragraph 1 */}
        <Typography
          sx={{
            color: Colors.textColor,
            fontWeight: "300",
            fontSize: {
              xs: "16px",
              sm: "18px",
              md: "20px",
              lg: "20px",
            },
            lineHeight: {
              xs: "24px",
              sm: "28px",
              md: "32px",
            },
            paddingTop: "20px",
            maxWidth: "800px",
            textAlign: "center",
          }}
        >
          Almost 3 billion people in the world work hands-on jobs – they fix
          doors, clean floors, manage stores, load crates, guard gates, set
          plates… Managing a business with employees like these is hard work –
          they have too many apps for work yet still lack the right ones.
        </Typography>
        <Typography
          sx={{
            color: Colors.textColor,
            fontWeight: "300",
            fontSize: {
              xs: "16px",
              sm: "18px",
              md: "20px",
              lg: "20px",
            },
            lineHeight: {
              xs: "24px",
              sm: "28px",
              md: "32px",
            },
            paddingTop: "20px",
            maxWidth: "800px",
            textAlign: "center",
          }}
        >
          We developed one complete solution for managers to easily run everyday
          operations, share knowledge, keep teams connected, and focus on
          growing the business and the bottom line.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <img
          style={{
            width: "100%",
            maxWidth: "1100px",
            height: "auto",
          }}
          src={wobb}
          alt="candidate"
          draggable={false}
        />
      </Box>
      {/* Section 3>>>> */}
      <Box
        sx={{
          backgroundColor: Colors.white,
          padding: "70px 20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: Colors.headingColor,
            fontWeight: "500",
            fontSize: {
              xs: "20px",
              sm: "20px",
              md: "20px",
              lg: "20px",
            },
            lineHeight: {
              xs: "40px",
              sm: "45px",
              md: "50px",
              lg: "60px",
            },
            textAlign: "center",
          }}
        >
          INNOVATING EVERYDAY WORK FOR
        </Typography>
        {/* Cards */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ paddingTop: "10px" }}
        >
          {cardData.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  padding: "20px",
                  backgroundColor: Colors.white,
                  borderRadius: "10px",
                }}
              >
                {card.icon}
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "500",
                    color: Colors.headingColor,
                    marginTop: "10px",
                  }}
                >
                  {card.heading}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: Colors.slate_grey,
                    marginTop: "10px",
                  }}
                >
                  {card.text}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* Section 4 Counters Cards>>>> */}
      <Box
        sx={{
          backgroundColor: Colors.white,
          padding: "70px 20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: Colors.headingColor,
            fontWeight: "500",
            fontSize: {
              xs: "20px",
              sm: "20px",
              md: "20px",
              lg: "20px",
            },
            lineHeight: {
              xs: "40px",
              sm: "45px",
              md: "50px",
              lg: "60px",
            },
            textAlign: "center",
          }}
        >
          NUMBERS WE'RE PROUD OF
        </Typography>
        {/* Grid of Cards */}
        <Box
          sx={{
            backgroundColor: Colors.white,
            padding: "40px 20px",
          }}
        >
          <Grid container spacing={3}>
            {stats.map((stat, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70px",
                    width: "150px",
                    padding: "60px",
                    backgroundColor: Colors.candiDateBackground,
                    borderRadius: "10px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                  }}
                >
                  {/* Number in Millions */}
                  <Typography
                    sx={{
                      fontSize: "50px",
                      fontWeight: "400",
                      color: Colors.headingColor,
                      marginBottom: "2px",
                    }}
                  >
                    {stat.number}
                  </Typography>
                  {/* Heading Text */}
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: Colors.textColor,
                    }}
                  >
                    {stat.heading}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* Image Section...>  */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingTop: "100px",
          }}
        >
          <img
            style={{
              width: "100%",
              maxWidth: "1250px",
              height: "auto",
            }}
            src={Group}
            alt="candidate"
            draggable={false}
          />
        </Box>
      </Box>
      {/* Section 5.... */}
      <Box
        sx={{
          padding: "5rem 3rem",
          background: Colors.pricingSectionBackgorund,
        }}
      >
        {/* Image Section...>  */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingBottom: "30px",
          }}
        >
          <img
            style={{
              width: "100%",
              maxWidth: "800px",
              height: "auto",
              objectFit: "cover",
            }}
            src={TrophyCase}
            alt="candidate"
            draggable={false}
          />
        </Box>
        <Grid container spacing={2} justifyContent="center">
          {aboutOptions.map((option, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  padding: "15px",
                  height: "80%",
                  position: "relative",
                  backgroundColor: Colors.white,
                  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                  borderRadius: "12px",
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.slate_grey,
                        fontWeight: "200",
                        fontSize: "1rem",
                      }}
                    >
                      {option.paymentText}
                    </Typography>
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: Colors.slate_black,
                      fontWeight: "500",
                      fontSize: "1.3rem",
                      marginBottom: "3rem",
                    }}
                  >
                    {option.title}
                  </Typography>
                  {/* Conditional rendering for buttons/icons */}
                  {option.title === "Follow us" ? (
                    // Social Media Icons for the 'Follow us' card
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        marginTop: "10px",
                      }}
                    >
                      <IconButton
                        aria-label="Facebook"
                        sx={{ color: Colors.headingColor }}
                        onClick={() =>
                          window.open("https://www.facebook.com", "_blank")
                        }
                      >
                        <FacebookOutlinedIcon sx={{ fontSize: 40 }} />
                      </IconButton>
                      <IconButton
                        aria-label="Instagram"
                        sx={{ color: Colors.headingColor }}
                        onClick={() =>
                          window.open("https://www.instagram.com", "_blank")
                        }
                      >
                        <InstagramIcon sx={{ fontSize: 40 }} />
                      </IconButton>
                      <IconButton
                        aria-label="LinkedIn"
                        sx={{ color: Colors.headingColor }}
                        onClick={() =>
                          window.open("https://www.linkedin.com", "_blank")
                        }
                      >
                        <LinkedInIcon sx={{ fontSize: 40 }} />
                      </IconButton>
                    </Box>
                  ) : (
                    <ButtonComponents
                      text={option.buttonText}
                      color={Colors.white}
                      background={`linear-gradient(to right, ${Colors.vivid_mulberry}, ${Colors.slate_blue})`} // Background style
                      width="43%"
                      onclick={() => handleButtonClick(option.title)}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default AboutUs;
