import React from "react";
import { Box, Typography } from "@mui/material";
import { Colors } from "../theme/Color";
import wobbling from "../assets/wobbling.svg";

const Candidates = ({ firstRef1 }: any) => {
  return (
    <>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          background: Colors.alternateSectionBackground,
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
        ref={firstRef1}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "35%",
            width: "90vw",
            height: "100%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "45vw",
              top: "50%",
              left: "55%",
              transform: "translate(-50%,-50%)",
              zIndex: "2",
            }}
          >
            <Box
              sx={{
                flexGrow: 5,
                width: "30%",
                marginLeft: "10%",
              }}
              className="videoTag"
            >
              <Box sx={{ width: "100%" }}>
                <iframe
                  width="560"
                  height="315"
                  rel={"0"}
                  style={{
                    border: 0,
                    borderRadius: 6,
                    backgroundColor: "#000",
                  }}
                  src="https://player.vimeo.com/video/831789635"
                  title="Velocity - The future. AI Generated Copilot for Recruiters"
                  allowFullScreen={false}
                  allow="autoplay; encrypted-media"
                ></iframe>
              </Box>
            </Box>
          </Box>
          <img
            style={{
              width: "76%",
              height: "100%",
              transform: "rotate(-71.79deg)",
            }}
            src={wobbling}
            alt="wobbling"
            draggable={false}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            maxWidth: {
              md: "800px",
              lg: "980px",
              xl: "1240px",
            },
            height: "100vh",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
            }}
          ></Box>
          <Box
            sx={{
              width: "50%",
              maxWidth: "560px",
            }}
          >
            <Typography
              sx={{
                padding: "0px",
                color: Colors.altHeadingColor,
                fontWeight: "700",
                fontSize: {
                  md: "30px",
                  lg: "35px",
                  xl: "40px",
                },
                lineHeight: {
                  md: "45px",
                  lg: "51px",
                  xl: "60px",
                },
              }}
            >
              Know who’s on the job with GPS tracking
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.altHeadingColor,
                paddingTop: "24px",
                ontWeight: 400,
                fontSize: {
                  md: "13px",
                  lg: "15px",
                  xl: "16px",
                },
                lineHeight: {
                  md: "20px",
                  lg: "20px",
                  xl: "24px",
                },
              }}
            >
              Schedule App job scheduling software is perfect for crews on the
              move. When you get a call for a job, you’ll know in seconds who’s
              on the clock and who’s closest to the new gig.  
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.altHeadingColor,
                paddingTop: "15px",
                ontWeight: 400,
                fontSize: {
                  md: "13px",
                  lg: "15px",
                  xl: "16px",
                },
                lineHeight: {
                  md: "20px",
                  lg: "20px",
                  xl: "24px",
                },
              }}
            >
              Open the Who’s Working window to view GPS points for clocked-in
              employees. Add the new job to the schedule and assign it to a team
              or individual.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.altHeadingColor,
                paddingTop: "15px",
                ontWeight: 400,
                fontSize: {
                  md: "13px",
                  lg: "15px",
                  xl: "16px",
                },
                lineHeight: {
                  md: "20px",
                  lg: "20px",
                  xl: "24px",
                },
              }}
            >
              GPS tracking provides real-time visibility into who’s on the job,
              enhancing accountability and efficiency. Stay informed about
              employee locations to optimize operations and keep projects on
              track!
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Mobile */}
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
            xl: "none",
          },
          background: Colors.alternateSectionBackground,
          clipPath: {
            xs: "polygon(0 0, 100% 0, 100% 93%, 0% 100%)",
            sm: "polygon(0 0, 100% 0, 100% 90%, 0% 100%)",
          },
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
            maxWidth: {
              xs: "400px",
              sm: "700px",
            },
            margin: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              margin: "auto",
              flexDirection: "column",
              maxWidth: {
                xs: "400px",
                sm: "570px",
              },
              paddingBottom: "2%",
            }}
          >
            <Typography
              sx={{
                padding: "0px",
                color: Colors.altHeadingColor,
                fontWeight: "700",
                fontSize: {
                  xs: "22px",
                  sm: "22px",
                },
                lineHeight: {
                  xs: "35px",
                  sm: "35px",
                },
                textAlign: "center",
              }}
            >
              Know who’s on the job with GPS tracking
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.alttextColor,
                paddingTop: "5px",
                ontWeight: 400,
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                },
                lineHeight: {
                  xs: "20px",
                  sm: "20px",
                },
                textAlign: "center",
              }}
            >
              Schedule App job scheduling software is perfect for crews on the
              move. When you get a call for a job, you’ll know in seconds who’s
              on the clock and who’s closest to the new gig.  
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.alttextColor,
                paddingTop: "5px",
                ontWeight: 400,
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                },
                lineHeight: {
                  xs: "20px",
                  sm: "20px",
                },
                textAlign: "center",
              }}
            >
              Open the Who’s Working window to view GPS points for clocked-in
              employees. Add the new job to the schedule and assign it to a team
              or individual.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.alttextColor,
                paddingTop: "5px",
                ontWeight: 400,
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                },
                lineHeight: {
                  xs: "20px",
                  sm: "20px",
                },
                textAlign: "center",
              }}
            >
              GPS tracking provides real-time visibility into who’s on the job,
              enhancing accountability and efficiency. Stay informed about
              employee locations to optimize operations and keep projects on
              track!Tironem helps you focus on what matters - quickly uncovering
              top talent.
            </Typography>
          </Box>
          <Box
            sx={{
              height: {
                xs: "43vh",
                sm: "58vh",
              },
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                flexGrow: 5,
                width: "30%",
                marginLeft: "10%",
              }}
              className="videoTag"
            >
              <Box sx={{ width: "100%" }}>
                <iframe
                  width="560"
                  height="315"
                  rel={"0"}
                  style={{
                    border: 0,
                    borderRadius: 6,
                    backgroundColor: "#000",
                  }}
                  src="https://player.vimeo.com/video/831789635"
                  title="Velocity - The future. AI Generated Copilot for Recruiters"
                  allowFullScreen={false}
                  allow="autoplay; encrypted-media"
                ></iframe>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Candidates;
