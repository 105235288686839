import React from "react";
import { Box, Card, CardContent, Typography, Grid2 } from "@mui/material";
import ButtonComponents from "./ButtonComponents";
import { Colors } from "../theme/Color";
import CheckIcon from "@mui/icons-material/Check";

interface PricingOption {
  title: string;
  price: string;
  paymentText: string;
  aiFeatures: string[];
  buttonLabel: string;
  isPopular?: boolean;
}

const pricingOptions: PricingOption[] = [
  {
    title: "One Off Purchase",
    price: "£10/month",
    paymentText: "just one",
    aiFeatures: [
      "AI Generated Job Descriptions",
      "AI Generated Interview Questions",
      "Bot Recorded Interviews",
      "Interview Transcriptions",
    ],
    buttonLabel: "Purchase",
  },
  {
    title: "Standard Plan",
    price: "£20/month",
    paymentText: "per year",
    aiFeatures: [
      "Interview Transcriptions",
      "Integration and posting with major jobs boards",
      "Interviews by AI Generated Avatar",
      " Everything from Free Plan",
      "Works out to be $180 per job",
    ],
    buttonLabel: "Purchase",
    isPopular: true,
  },
  {
    title: "Premium Plan",
    price: "£30/month",
    paymentText: "per year",
    aiFeatures: [
      "Everything from Standard Plan",
      "Works out to be $150 per job",
    ],
    buttonLabel: "Purchase ",
  },
];

const PricingCards: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          padding: "10rem 5rem 3rem 5rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: Colors.pricingSectionBackgorund,
        }}
      >
        <Grid2 container spacing={4} justifyContent="center">
          {pricingOptions.map((option, index) => (
            <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Card
                sx={{
                  padding: "2rem",
                  height: "80%",
                  position: "relative",
                  backgroundColor: Colors.white,
                  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                  borderRadius: "12px",
                  border: "1px solid #EC7211",
                  transition: "transform 0.3s ease-in-out",
                  transform: option.isPopular ? "scale(1.05)" : "scale(1)",
                  "&:hover": {
                    transform: option.isPopular ? "scale(1.05)" : "scale(1.05)",
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      color: Colors.slate_black,
                      fontWeight: "500",
                      fontSize: "1.3rem",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {option.title}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: Colors.slate_blue,
                        fontWeight: "700",
                        fontSize: "2rem",
                        marginBottom: "1rem",
                        paddingRight: "10px",
                      }}
                    >
                      {option.price}
                    </Typography>
                    <Typography
                      sx={{
                        color: Colors.slate_grey,
                        fontWeight: "200",
                        fontSize: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      {option.paymentText}
                    </Typography>
                  </Box>
                  <ButtonComponents
                    text={option.buttonLabel}
                    color={Colors.white}
                    background={`linear-gradient(to right, ${Colors.vivid_mulberry}, ${Colors.slate_blue})`}
                    width="100%"
                  />
                  <Box
                    sx={{
                      marginTop: "1rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "500",
                        marginBottom: "0.5rem",
                        color: Colors.slate_black,
                      }}
                    >
                      Includes:
                    </Typography>
                    {option.aiFeatures.map((feature, i) => (
                      <Box
                        key={i}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <CheckIcon
                          sx={{
                            color: Colors.slate_blue,
                            marginRight: "0.5rem",
                            fontSize: "1.2rem",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            color: Colors.slate_grey,
                          }}
                        >
                          {feature}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </>
  );
};

export default PricingCards;
